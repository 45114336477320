@import '~@/assets/scss/main.scss';

#avatar {
    width: 12rem;
    margin-bottom: 1rem;
}

#profile-images-container {
    max-height: 80vh;
    width: 65vw;

    @include media-breakpoint-down(md) {
        width: 75vw;
    }
}
