@import '~@/assets/scss/main.scss';

.theme-card {
    @include material-shadow(2);
    @extend .bg-white;
    border-radius: $border-radius;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 17rem;

    p {
        font-size: 2rem;
        @extend .font-raleway;
    }
}
